<template>

    <Head>
        <title>{{ page.meta_title }}</title>
        <meta name="description" v-if="page.meta_description" :content="page.meta_description">
    </Head>

    <Header :menu_items="menu_items" :page="page" :editable="editable" :website="website"/>


    <section class="text-gray-600 body-font section-text min-h-[calc(100vh_-_11.6rem)]">
        <div class="bg-white dark:bg-black dark:text-white">
            <div>
                <main class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                    <div class="flex flex-wrap px-1 pb-5 pt-10">
                        <h1>Gedeelde favorieten</h1>

                        <div class="grid grid-cols-2 md:grid-cols-4 gap-4 px-1 p-2  w-full">
                            <div class="col-span-2" v-if="website.favorite_shared.length===0">
                                Geen gedeelte favorieten.
                            </div>
                            <div v-for="(variant) in website.favorite_shared" :key="variant.id" class="!no-underline group relative border-gray-100 rounded-2xl p-0 sm:p-3 cursor-pointer    hover:border-gray-400     hover:scale-110 bg-white  transition ease-in-out delay-150 text-gray-700 dark:text-white dark:bg-gray-700">

                               <span class="px-2 absolute right-0 z-100 bg-white h-9 rounded" @click="addFavourite(variant.id)">
                                   <CustomSvg name="Xmark" customClass="fill-blue-800 h-2 z-100"></CustomSvg>
                               </span>

                                <span v-if="variant.product.price_percentage!==undefined && variant.product.price_percentage!==0 && variant.product.price_percentage!=='0'"  class="bg-green-700 rounded p-0.5 px-2 absolute text-white z-10">
                                    {{ variant.product.price_percentage }} %
                                </span>
                                <jet-nav-link :href="'/'+variant.canonical" class="!no-underline">
                                    <div class="group relative mx-auto w-full rounded-lg dark:bg-white p-1">
                                        <div class="hero container max-w-screen-lg mx-auto  h-44 w-full flex items-center max-w">

                                            <img :src="variant.first_picture.PictureUrlFull" class="max-h-44 mx-auto"
                                                 :alt="variant.first_picture.PictureUrlFull"/>
                                        </div>
                                    </div>
                                    <h3 class="text-xs sm:text-sm  no-underline h-24 mt-3">
                                        {{ variant.product.name.substring(0, 80) }}<span v-if="variant.product.name.length > 80">...</span>
                                    </h3>

                                    <div v-if="Object.keys(variant.prices_ordered).length> 1" class="text-xs font-light float-left mt-5">
                                        {{ Object.keys(variant.prices_ordered).length }} prijzen
                                    </div>
                                    <div class="text-sm font-medium float-right text-sm text-xl font-bold mt-2">
                                        {{ currency(variant.prices_ordered[0].price_discount / 100) }}
                                    </div>
                                </jet-nav-link>
                            </div>
                        </div>

                    </div>
                </main>
            </div>
        </div>
    </section>

    <Footer :menu_items="menu_items" :page="page" :website="website">
        <template #content_website_footer_left>
            <slot name="content_website_footer_left">
            </slot>
        </template>
        <template #content_website_footer_center>
            <slot name="content_website_footer_center">
            </slot>
        </template>
        <template #content_website_footer_right>
            <slot name="content_website_footer_right">
            </slot>
        </template>
    </Footer>
</template>


<script>

import Header from '@/Pages/Admin/Page/Templates/Partials/Header.vue';
import Footer from '@/Pages/Admin/Page/Templates/Partials/Footer.vue';
import JetNavLink from '@/Jetstream/NavLink.vue'
import {Head} from "@inertiajs/vue3";

export default {

    components: {
        Header,
        Footer,
        Head,JetNavLink

    },
    props: {
        menu_items: Object,
        page: Object,
        editable: Boolean,
        website: Object,
    },

    data() {
        return {}
    },
    methods: {
        itemsContains(n) {
            return this.page.page_options.indexOf(n) > -1
        }
    }
}
</script>

